import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Footer from "../Footer";
import BuyModal from "../Modals/BuyModal";
import "../ComponentStyles.css";

export default function Buy() {
  const [open, setOpen] = useState(false);

  const handleClose = (value) => {
    setOpen(false);
  };

  const Cakes = [
    {
      name: "Vanilla Cake",
      price: "445",
      veg: true,
    },
    {
      name: "Butterscotch Cake",
      price: "445",
      veg: true,
    },
    {
      name: "Caramel Cake",
      price: "445",
      veg: true,
    },
    {
      name: "Pineapple Cake",
      price: "445",
      veg: true,
    },
    {
      name: "Blueberry Cake",
      price: "510",
      veg: true,
    },
    {
      name: "Black Forest Cake",
      price: "510",
      veg: true,
    },
    {
      name: "Mocha Cake",
      price: "510",
      veg: true,
    },
    {
      name: "Chocolate Cake",
      price: "575",
      veg: true,
    },
    {
      name: "Red Velvet Cake",
      price: "700",
      veg: true,
    },
    {
      name: "Death By Chocolate",
      price: "700",
      veg: true,
    },
    {
      name: "White Chocolate Cake",
      price: "765",
      veg: true,
    },
    {
      name: "Rainbow Cake",
      price: "1400",
      veg: true,
    },
  ];

  const CupCakesNPastries = [
    {
      name: "Caramel Cup Cake",
      price: "65",
      veg: true,
    },

    {
      name: "Chocolate Cup Cake",
      price: "75",
      veg: true,
    },

    {
      name: "Black Forest Pastry",
      price: "85",
      veg: true,
    },

    {
      name: "Blueberry Pastry",
      price: "90",
      veg: true,
    },

    {
      name: "Mocha Pastry",
      price: "95",
      veg: true,
    },

    {
      name: "Red Velvet Pastry",
      price: "100",
      veg: true,
    },

    {
      name: "Swirl Pastry",
      price: "110",
      veg: true,
    },
  ];

  const CookiesTeaCakes = [
    {
      name: "Peanut Butter Cookie",
      price: "25",
      veg: 25,
    },
    {
      name: "Chocochip Cookie",
      price: "25",
      veg: true,
    },
    {
      name: "Marble Slice",
      price: "35",
      veg: false,
    },
    {
      name: "Banana Bread Loaf",
      price: "410",
      veg: false,
    },
  ];

  const BrowniesNMore = [
    {
      name: "Brownie",
      price: "70",
      veg: false,
    },
    {
      name: "Blondie",
      price: "75",
      veg: false,
    },
    {
      name: "Red Velvet Brownie",
      price: "90",
      veg: false,
    },
    {
      name: "Cake Sickle",
      price: "35",
      veg: true,
    },
    {
      name: "Chocolate Tart",
      price: "65",
      veg: true,
    },
    {
      name: "Peanut Butter Tart",
      price: "65",
      veg: true,
    },
    {
      name: "Classic Macaroon",
      price: "75",
      veg: true,
    },
    {
      name: "Strawberry Macaroon",
      price: "90",
      veg: true,
    },
    {
      name: "Dark Chocolate Pudding",
      price: "100",
      veg: true,
    },
    {
      name: "White Chocolate Pudding",
      price: "115",
      veg: true,
    },
  ];

  const Savouries = [
    {
      name: "Chicken Bun",
      price: "60",
      veg: false,
    },
    {
      name: "Paneer Bun",
      price: "60",
      veg: true,
    },
    {
      name: "Veg Bagel Sandwich",
      price: "120",
      veg: true,
    },
  ];

  return (
    <Box
      sx={{
        "@media (max-width: 1000px)": {
          paddingX: "20px",
        },
      }}
    >
      <Typography
        fontSize="28px"
        color="#91263C"
        marginY="30px"
        sx={{
          "@media (max-width: 1000px)": {
            marginTop: "40px",
            marginBottom: "10px",
            fontSize: "22px",
          },
        }}
      >
        Cakes
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          "@media (max-width: 1000px)": {
            paddingX: "3vw",
          },
        }}
        paddingX="110px"
        className="container-buy"
        mt={3}
      >
        <Grid container spacing={10}>
          {Cakes.map((item) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  border="2px solid black"
                  borderRadius="30px 30px 30px 30px"
                  alignItems="center"
                  paddingY="20px"
                >
                  <Box
                    height="100px"
                    paddingY="100px"
                    sx={{
                      "@media (max-width: 1000px)": {
                        height: "50px",
                      },
                    }}
                  >
                    <Typography variant="h6">Image Unavailable</Typography>
                  </Box>
                  <Typography
                    fontSize="18px"
                    sx={{
                      "@media (max-width: 1000px)": {
                        fontSize: "20px",
                      },
                    }}
                    color="#91263C"
                  >
                    {item.name}
                    {item.veg && "(Eggless)"}
                  </Typography>
                  <Typography
                    fontSize="16px"
                    mt={1}
                    sx={{
                      "@media (max-width: 1000px)": {
                        fontSize: "20px",
                      },
                    }}
                    color="#91263C"
                    mb={2}
                  >
                    Price: Rs. {item.price}
                  </Typography>
                  <Button
                    variant="contained"
                    mt={2}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Buy Now
                  </Button>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Typography
        fontSize="28px"
        mt={15}
        color="#91263C"
        sx={{
          "@media (max-width: 1000px)": {
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "22px",
          },
        }}
      >
        Cupcakes and Pastries
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          "@media (max-width: 1000px)": {
            paddingX: "3vw",
          },
        }}
        paddingX="110px"
        className="container-buy"
        mt={3}
      >
        <Grid container spacing={10}>
          {CupCakesNPastries.map((item) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  border="2px solid black"
                  borderRadius="30px 30px 30px 30px"
                  alignItems="center"
                  paddingY="20px"
                >
                  <Box
                    height="100px"
                    paddingY="100px"
                    sx={{
                      "@media (max-width: 1000px)": {
                        height: "50px",
                      },
                    }}
                  >
                    <Typography variant="h6" color="#91263C">
                      Image Unavailable
                    </Typography>
                  </Box>
                  <Typography
                    fontSize="18px"
                    sx={{
                      "@media (max-width: 1000px)": {
                        fontSize: "20px",
                      },
                    }}
                    color="#91263C"
                  >
                    {item.name}
                    {item.veg && "(Eggless)"}
                  </Typography>
                  <Typography
                    fontSize="18px"
                    mt={1}
                    sx={{
                      "@media (max-width: 1000px)": {
                        fontSize: "20px",
                      },
                    }}
                    color="#91263C"
                    mb={2}
                  >
                    Price: Rs. {item.price}
                  </Typography>
                  <Button
                    variant="contained"
                    mt={2}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Buy Now
                  </Button>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Typography
        fontSize="28px"
        mt={15}
        color="#91263C"
        sx={{
          "@media (max-width: 1000px)": {
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "22px",
          },
        }}
      >
        Cookies and Tea Cookies
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          "@media (max-width: 1000px)": {
            paddingX: "3vw",
          },
        }}
        paddingX="110px"
        className="container-buy"
        mt={3}
      >
        <Grid container spacing={10}>
          {CookiesTeaCakes.map((item) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  border="2px solid black"
                  borderRadius="30px 30px 30px 30px"
                  alignItems="center"
                  paddingY="20px"
                >
                  <Box
                    height="100px"
                    paddingY="100px"
                    sx={{
                      "@media (max-width: 1000px)": {
                        height: "50px",
                      },
                    }}
                  >
                    <Typography variant="h6">Image Unavailable</Typography>
                  </Box>
                  <Typography
                    fontSize="18px"
                    color="#91263C"
                    sx={{
                      "@media (max-width: 1000px)": {
                        fontSize: "20px",
                      },
                    }}
                  >
                    {item.name}
                    {item.veg && "(Eggless)"}
                  </Typography>
                  <Typography
                    fontSize="18px"
                    mt={1}
                    sx={{
                      "@media (max-width: 1000px)": {
                        fontSize: "20px",
                      },
                    }}
                    color="#91263C"
                    mb={2}
                  >
                    Price: Rs. {item.price}
                  </Typography>
                  <Button
                    variant="contained"
                    mt={2}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Buy Now
                  </Button>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Typography
        fontSize="28px"
        mt={15}
        color="#91263C"
        sx={{
          "@media (max-width: 1000px)": {
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "22px",
          },
        }}
      >
        Brownies and more
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          "@media (max-width: 1000px)": {
            paddingX: "3vw",
          },
        }}
        paddingX="110px"
        className="container-buy"
        mt={3}
      >
        <Grid container spacing={10}>
          {BrowniesNMore.map((item) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  border="2px solid black"
                  borderRadius="30px 30px 30px 30px"
                  alignItems="center"
                  paddingY="20px"
                >
                  <Box
                    height="100px"
                    paddingY="100px"
                    sx={{
                      "@media (max-width: 1000px)": {
                        height: "50px",
                      },
                    }}
                  >
                    <Typography                     fontSize="18px">Image Unavailable</Typography>
                  </Box>
                  <Typography
                    fontSize="18px"
                    sx={{
                      "@media (max-width: 1000px)": {
                        fontSize: "20px",
                      },
                    }}
                    color="#91263C"
                  >
                    {item.name}
                    {item.veg && "(Eggless)"}
                  </Typography>
                  <Typography
                    fontSize="18px"
                    mt={1}
                    sx={{
                      "@media (max-width: 1000px)": {
                        fontSize: "20px",
                      },
                    }}
                    color="#91263C"
                    mb={2}
                  >
                    Price: Rs. {item.price}
                  </Typography>
                  <Button
                    variant="contained"
                    mt={2}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Buy Now
                  </Button>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Typography
        fontSize="28px"
        mt={15}
        color="#91263C"
        sx={{
          "@media (max-width: 1000px)": {
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "22px",
          },
        }}
      >
        Savouries
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          "@media (max-width: 1000px)": {
            paddingX: "3vw",
          },
        }}
        paddingX="110px"
        className="container-buy"
        mt={3}
      >
        <Grid container spacing={10}>
          {Savouries.map((item) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  border="2px solid black"
                  borderRadius="30px 30px 30px 30px"
                  alignItems="center"
                  paddingY="20px"
                >
                  <Box
                    height="100px"
                    paddingY="100px"
                    sx={{
                      "@media (max-width: 1000px)": {
                        height: "50px",
                      },
                    }}
                  >
                    <Typography                     fontSize="18px">Image Unavailable</Typography>
                  </Box>
                  <Typography
                    fontSize="18px"
                    sx={{
                      "@media (max-width: 1000px)": {
                        fontSize: "20px",
                      },
                    }}
                    color="#91263C"
                  >
                    {item.name}
                    {item.veg && "(Eggless)"}
                  </Typography>
                  <Typography
                    fontSize="18px"
                    mt={1}
                    mb={2}
                    sx={{
                      "@media (max-width: 1000px)": {
                        fontSize: "20px",
                      },
                    }}
                    color="#91263C"
                  >
                    Price: Rs. {item.price}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Buy Now
                  </Button>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Footer />
      <BuyModal open={open} onClose={handleClose} />
    </Box>
  );
}
