import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import muffin from "../assets/muffin.webp";
import muffinblur from "../assets/muffinblur.jpg";
import "./ComponentStyles.css";
import { Link as RouterLink } from "react-router-dom";
export default function About() {
  return (
    <Box
      display="flex"
      flexDirection="row"
      mx="auto"
      mt="120px"
      width="90vw"
      height="320px"
      backgroundColor="#f7e5e4"
      justifyContent="flex-start"
      paddingY="25px"
      borderRadius="40px"
      boxShadow="3px 3px #f7e5e4"
      sx={{
        "@media (max-width: 1000px)": {
          flexDirection: "column",
          paddingY: "15px",
          marginTop: "40px",
          paddingX: "10px",
          marginX: "0px",
          width: "80vw",
          height: "550px",
        },
      }}
    >
      <Box
        component="img"
        width="40vw"
        height="320px"
        src={muffin}
        borderRadius="40px"
        boxShadow="2px 2px #ffa4af"
        mx={4}
        sx={{
          "@media (max-width: 1000px)": {
            width: "80vw",
            height: "190px",
            marginX: "0px",
          },
        }}
      />
      <Box
        display="flex"
        width="40vw"
        flexDirection="column"
        alignItems="flex-start"
        marginLeft="30px"
        className="container-about"
        sx={{
          "@media (max-width: 1000px)": {
            width: "70vw",
            marginTop: "20px",
          },
        }}
      >
        <Typography
          fontSize="24px"
          color="#91263C"
          sx={{
            "@media (max-width: 1000px)": {
              fontSize: "28px",
            },
          }}
        >
          About
        </Typography>
        <Typography
          fontSize="24x"
          color="#91263C"
          sx={{
            "@media (max-width: 1000px)": {
              fontSize: "28px",
            },
          }}
        >
          Cake Affairé
        </Typography>
        <Typography
          fontSize="21px"
          paragraph
          wordWrap="break-word"
          textAlign="left"
          mt={3}
          sx={{
            "@media (max-width: 1000px)": {
              fontSize: "18px",
              fontStyle: "italic",
              marginTop: "10px",
            },
          }}
        >
          A startup by brother-sister duo passionate about baking. Our store is
          the home of authentic cupcakes, cakes and baked goods for pickup or
          delivery. Our goal is to bring to you premium cakes at best prices.
        </Typography>
        <RouterLink component="a" to="/about" className="atag">
          <Box
            mt={3}
            sx={{
              "@media (max-width: 1000px)": {
                marginTop: "10px",
              },
            }}
          >
            {" "}
            <Button variant="contained">Read More</Button>
          </Box>{" "}
        </RouterLink>
      </Box>
    </Box>
  );
}
