import { Box } from "@mui/material";
import Landing from "./Landing";
import LandingMobile from "./LandingMobile";
import About from "./About";
import CustomerPicks from "./CustomerPicks";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
function Container() {
  return (
    <Box
      background="linear-gradient(45deg, #FFFFFF 0%, #FFE3F8 100%)"
      sx={{
        "@media (max-width: 1000px)": {
          paddingLeft: "20%",
        },
      }}
    >
      <Landing />
      <LandingMobile />
      <CustomerPicks />
      <About />
      <Testimonial />
      <Footer />
    </Box>
  );
}

export default Container;
