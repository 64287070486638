import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import SearchBar from "./PureComponents/SearchBar";
import "./ComponentStyles.css";
import { Link as RouterLink } from "react-router-dom";

export default function Navbar() {
  return (
    <Box
      className="container-appbar"
      mt="10px"
      sx={{
        "@media (max-width: 1000px)": {
          display: "none",
        },
      }}
    >
      <AppBar position="static" className="container-menu">
        <Box display="flex" flexDirection="row">
          <Toolbar disableGutters>
            <Box
              sx={{ display: "flex", flexDirection: "row" }}
              className="cnr-navroute"
            >
              <RouterLink component="a" to="/" className="atag">
                <Button sx={{ my: 2, color: "black", display: "block", mx: 2 }}>
                  <Typography fontSize="16px">HOME</Typography>
                </Button>
              </RouterLink>
              <RouterLink component="a" to="/buy" className="atag">
                <Button sx={{ my: 2, color: "black", display: "block", mx: 2 }}>
                  <Typography fontSize="16px">BUY</Typography>
                </Button>{" "}
              </RouterLink>
              <RouterLink component="a" to="/about" className="atag">
                <Button sx={{ my: 2, color: "black", display: "block", mx: 2 }}>
                  <Typography fontSize="16px">ABOUT</Typography>
                </Button>{" "}
              </RouterLink>
            </Box>
          </Toolbar>
          <Box className="container-search">
            <SearchBar />
          </Box>
        </Box>
      </AppBar>
    </Box>
  );
}
