import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import "./ComponentStyles.css";
export default function Footer() {
  return (
    <Box
      width="100vw"
      height="140px"
      backgroundColor="#47181B"
      display="flex"
      flexDirection="row"
      justifyContent="space-evenly"
      marginTop="150px"
      paddingY="80px"
      sx={{
        "@media (max-width: 1000px)": {
          paddingTop: "15px",
          height: "120px",
          marginTop: "30px",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          "@media (max-width: 1000px)": {
            display: "none",
          },
        }}
      >
        <Typography color="white" fontSize="18px">
          Shop Now
        </Typography>
        <Typography color="white" fontSize="18px">
          Our Team
        </Typography>
        <Typography color="white" fontSize="18px">
          Contact Us
        </Typography>
        <Typography color="white" fontSize="18px">
          Location
        </Typography>
        <Typography color="white" fontSize="18px">
          Menu
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" className="container-divider">
        <Typography
          color="white"
          fontSize="28px"
          sx={{
            "@media (max-width: 1000px)": {
              fontSize: "25px",
            },
          }}
        >
          Cake Affairé-Gowri's Patisserie and Cafe
        </Typography>
        <Box className="container-ftricon" mt={3}>
          <a
            href="https://www.instagram.com/cake_affaire.store/"
            target="_blank"
            className="footer-atag"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://l.instagram.com/?u=https%3A%2F%2Fwa.me%2Fmessage%2FWJBZOGR764N6A1&e=ATPLrvk2KezxziidOWK726dOuBD_4qqP-Dss0ii9BP3lJz0vj7qfwECVLGLk9kY4jsVqCgr6tKwwo8h_&s=1"
            target="_blank"
            className="footer-atag"
          >
            {" "}
            <WhatsAppIcon />
          </a>
        </Box>
        <Divider />
        <Typography
          color="white"
          fontSize="18px"
          mt={3}
          sx={{
            "@media (max-width: 1000px)": {
              fontSize: "15px",
            },
          }}
        >
          &copy; Cake Affairé 2024
        </Typography>
      </Box>
      <Box
        className="container-scrollup"
        sx={{
          "@media (max-width: 1000px)": {
            display: "none",
          },
        }}
      >
        <KeyboardDoubleArrowUpIcon />
        <Typography color="white" fontSize="18px">
          Back To Top
        </Typography>
      </Box>
    </Box>
  );
}
