import "./App.css";
import Container from "./Components/Container";
import MeetTeam from "./Components/Pages/MeetTeam";
import Buy from "./Components/Pages/Buy";
import Location from "./Components/Pages/Location";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Menubar from "./Components/Menubar";
import Navbar from "./Components/Navbar";

function App() {
  return (
    <div className="App">
      <Menubar />
      <Navbar />
        <Routes>
          <Route path="/" element={<Container />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/about" element={<MeetTeam />} />
          <Route path="/location" element={<Location />} />
        </Routes>
    </div>
  );
}

export default App;
