import { Box, Typography, Button } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import logowhite from "../assets/logowhite.jpg";
import "./ComponentStyles.css";
import { Link as RouterLink } from "react-router-dom";

function Menubar() {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      paddingY="auto"
      width="100vw"
      backgroundColor="white"
      height="84px"
      sx={{
        "@media (max-width: 1000px)": {
          flexDirection: "column",
          paddingY: "1px",
          height: "100px",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginY="auto"
        className="container-menuicon"
        mx={5}
      >
        <a
          href="https://www.instagram.com/cake_affaire.store/"
          target="_blank"
          className="menu-atag"
        >
          <InstagramIcon
            sx={{
              marginRight: "0.2vw",
              "@media (max-width: 1000px)": {
                display: "none",
              },
            }}
          />
        </a>
        <a
          href="https://l.instagram.com/?u=https%3A%2F%2Fwa.me%2Fmessage%2FWJBZOGR764N6A1&e=ATPLrvk2KezxziidOWK726dOuBD_4qqP-Dss0ii9BP3lJz0vj7qfwECVLGLk9kY4jsVqCgr6tKwwo8h_&s=1"
          target="_blank"
          className="menu-atag"
        >
          {" "}
          <WhatsAppIcon
            sx={{
              marginRight: "0.2vw",
              "@media (max-width: 1000px)": {
                display: "none",
              },
            }}
          />
        </a>
        <CallIcon
          sx={{
            marginRight: "0.2vw",
            "@media (max-width: 1000px)": {
              display: "none",
            },
          }}
        />
        <Typography
          fontSize="16px"
          mx="5px"
          sx={{
            "@media (max-width: 1000px)": {
              display: "none",
            },
          }}
        >
          +91-6303702232
        </Typography>
      </Box>
      <Box
        width="321px"
        height="60px"
        marginLeft="15%"
        marginRight="20%"
        marginTop="auto"
        sx={{
          "@media (max-width: 1000px)": {
            width: "80vw",
            height: "70px",
          },
        }}
      >
        <RouterLink component="a" to="/">
          <Box
            component="img"
            width="321px"
            height="60px"
            src={logowhite}
            sx={{
              "@media (max-width: 1000px)": {
                width: "80vw",
                height: "70px",
              },
            }}
          />
        </RouterLink>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        marginY="auto"
        marginLeft="10%"
        className="container-menuicon"
        sx={{
          marginRight: "0.5vw",
          "@media (max-width: 1000px)": {
            display: "none",
          },
        }}
      >
        <RouterLink component="a" to="/location" className="menu-atag">
          <LocationOnOutlinedIcon />
        </RouterLink>
      </Box>
    </Box>
  );
}

export default Menubar;
