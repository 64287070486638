import * as React from "react";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import velvetcake from "../assets/velvetcake.jpg";
import velvetcakeblur from "../assets/velvetcakeblur.jpg";
import image from "../assets/image.jpg";
import "./ComponentStyles.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "Save 25%! Christmas Sale",
    imgPath:`${velvetcake}`,
  },
  {
    label: "Save 25%! Christmas Sale",
    imgPath: `${velvetcake}`,
  },
  {
    label: "Save 25%! Christmas Sale",
    imgPath: `${velvetcake}`,
  },
  {
    label: "Save 25%! Christmas Sale",
    imgPath: `${velvetcake}`,
  },
];

export default function Landing() {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        mt: "10px",
        width: "90vw",
        height: "800px",
        "@media (max-width: 1000px)": {
          display: "none",
          mt: "0px"
        },
      }}
      className="container-landing"
    >
      <Box marginLeft="5vw">
        <AutoPlaySwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          <Box>
            <Box
              sx={{
                height: "600px",
                width: "75vw",
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "repeat-x",
              }}
              textAlign="left"
              paddingLeft="90px"
            >
              <Typography
                fontSize="26px"
                fontWeight="bold"
                color="#91263C"
                textShadow="2px 2px white"
                paddingTop="50px"
                paddingLeft="150px"
              >
                Enjoy our delicious cakes! Order Now.{" "}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                height: "600px",
                width: "75vw",
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "repeat-x",
              }}
              textAlign="left"
              paddingLeft="90px"
            >
              <Typography
                fontSize="26px"
                fontWeight="bold"
                color="#91263C"
                textShadow="2px 2px white"
                paddingTop="50px"
                paddingLeft="150px"
              >
                Enjoy our delicious cakes! Order Now.{" "}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                height: "600px",
                width: "75vw",
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "repeat-x",
              }}
              textAlign="left"
              paddingLeft="90px"
            >
              <Typography
                fontSize="26px"
                fontWeight="bold"
                color="#91263C"
                textShadow="2px 2px white"
                paddingTop="50px"
                paddingLeft="150px"
              >
                Enjoy our delicious cakes! Order Now.{" "}
              </Typography>
            </Box>
          </Box>
        </AutoPlaySwipeableViews>
      </Box>
      <Box marginLeft="45vw">
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={null}
          backButton={null}
        />
      </Box>
    </Box>
  );
}
