import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
export default function Location() {
  return (
    <Box sx={{ width: "100vw", height: "100vh" }}>
      <Box>
        <Typography fontSize="26px" fontWeight="bold" textAlign="left" ml={12} mt={4}>
          Location :
        </Typography>
        <Typography fontSize="24px" textAlign="left" ml={12} mt={2}>
          G 10-3, 152/5, Teachers Colony Rd, beside HDFC bank, Teachers Colony,
          East Marredpally, Secunderabad, Telangana 500026.
        </Typography>
      </Box>
      <Box mt={4}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15225.098029415216!2d78.49945658580717!3d17.44657064182728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b9e734e8c1d%3A0x1d0d1ca4070e1e2d!2sCake%20Affair%C3%A9%20-The%20Neighborhood%20Bakery!5e0!3m2!1sen!2sin!4v1650539613990!5m2!1sen!2sin"
          width="60%"
          height="700"
          loading="lazy"
        ></iframe>
      </Box>
    </Box>
  );
}
