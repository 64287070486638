import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import image from "../assets/image.jpg";

export default function LandingMobile() {
  return (
    <Box
      display="none"
      sx={{
        "@media (max-width: 1000px)": {
          display: "block",
          mt: "45px",
        },
      }}
    >
      <Box
        sx={{
          height: "200px",
          width: "85vw",
          backgroundImage: `url(${image})`,
          backgroundRepeat: "round",
          borderRadius: "15px 15px 15px 15px",
        }}
        textAlign="left"
        paddingLeft="4vw"
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          color="#91263C"
          textShadow="2px 2px white"
          paddingTop="130px"
        >
          Enjoy our delicious cakes!{" "}
          <Typography
            variant="h6"
            fontWeight="bold"
            color="#91263C"
            textShadow="2px 2px white"
          >
            Order Now.{" "}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
}
