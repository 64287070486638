import React from "react";
import { Box, Typography } from "@mui/material";
import Footer from "../Footer";

export default function MeetTeam() {
  return (
    <Box>
      {" "}
      <Box width="100vw" height="600px" textAlign="center" paddingTop="300px">
        <Typography variant="h3">Coming Soon.</Typography>
      </Box>{" "}
      <Footer />
    </Box>
  );
}
