import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import "./ComponentStyles.css";

const card1 = (
  <React.Fragment>
    <CardContent>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className="container-rating"
        py={3}
      >
        <Typography fontSize="24px">Sharath J</Typography>
        <Rating name="simple-controlled" value="5" />
        <Box width="90%" mt={3}>
          <Typography
            fontSize="18px"
            textAlign="center"
            sx={{
              "@media (max-width: 1000px)": {
                fontSize: "18px",
              },
            }}
          >
            Somehow I had been planning to get a cake since an year, but it was
            my parents wedding anniversary that cinched the deal. My mother
            being allergic to Gluten, I had to ask for an alternative. To my
            surprise, Srinivas said he'll fulfill my request and suggested
            Almond Flour. With the toppings, decoration and most importantly the
            Taste, he had sealed the deal. If you're looking for a Gluten-free
            Cake or even Lactose Free Cake, then Cake Affairé is the place to
            go.{" "}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </React.Fragment>
);

const card2 = (
  <React.Fragment>
    <CardContent>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className="container-rating"
        py={3}
      >
        <Typography fontSize="24px">Preethika K</Typography>
        <Rating name="simple-controlled" value="5" />
        <Box width="90%" mt={3}>
          <Typography
            fontSize="18px"
            textAlign="center"
            sx={{
              "@media (max-width: 1000px)": {
                fontSize: "18px",
              },
            }}
          >
            This place has never disappointed me. I’ve tried cupcakes,
            customised cakes, dry cakes, cakesicles and I can go onnn… The taste
            is as good as how it looks, fresh and delicious. The ambience is
            cute and comfortable. If you live in Secunderabad or close to East
            Marredpally then this one is your go-to bakery{" "}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </React.Fragment>
);

const card3 = (
  <React.Fragment>
    <CardContent>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className="container-rating"
        py={3}
      >
        <Typography fontSize="24px">Shirley S</Typography>
        <Rating name="simple-controlled" value="5" />
        <Box width="90%" mt={3}>
          <Typography
            fontSize="18px"
            textAlign="center"
            sx={{
              "@media (max-width: 1000px)": {
                fontSize: "18px",
              },
            }}
          >
            I ordered the cake in morning and they were happy to make it at very
            short notice. It was my brother's 18th birthday and I wanted
            something special and the cake we got was the over expectation at
            reasonable rate.my brother really liked the cake..... and the taste
            was very tasty too❤️❤️Overall i would recommend everyone to get
            their personalized cakes here for any occasion{" "}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </React.Fragment>
);

export default function Testimonial() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt="100px"
      sx={{
        "@media (max-width: 1000px)": {
          marginTop: "50px",
        },
      }}
    >
      <Typography
        fontSize="28px"
        color="#91263C"
        textShadow="2px 2px black"
        sx={{
          "@media (max-width: 1000px)": {
            fontSize: "24px",
          },
        }}
      >
        Testimonials
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        className="container-card"
        mt="40px"
        sx={{
          "@media (max-width: 1000px)": {
            flexDirection: "column",
            marginTop: "30px",
          },
        }}
      >
        <Box
          sx={{
            width: "25%",
            "@media (max-width: 1000px)": {
              width: "90vw",
              marginBottom: "10px",
            },
          }}
        >
          <Card variant="outlined">{card1}</Card>
        </Box>
        <Box
          sx={{
            width: "25%",
            "@media (max-width: 1000px)": {
              width: "90vw",
              marginBottom: "10px",
            },
          }}
        >
          <Card variant="outlined">{card2}</Card>
        </Box>
        <Box
          sx={{
            width: "25%",
            "@media (max-width: 1000px)": {
              width: "90vw",
            },
          }}
        >
          <Card variant="outlined">{card3}</Card>
        </Box>
      </Box>
    </Box>
  );
}
