import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SwipeableViews from "react-swipeable-views";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import BuyModal from "./Modals/BuyModal";
import blueberrycake from "../assets/blueberrycake.png";
import chocolatecake from "../assets/chocolatecake.png";
import chocolatecupcake from "../assets/chocolatecupcake.jpg";
import redvelvet from "../assets/redvelvet.jpeg";
import "./ComponentStyles.css";
import { Link as RouterLink } from "react-router-dom";

const images = [
  {
    label: "Red Velvet Cake",
    imgPath: redvelvet,
  },
  {
    label: "Blueberry Cake",
    imgPath: blueberrycake,
  },
  {
    label: "Chocolate Cup Cake",
    imgPath: chocolatecupcake,
  },
  {
    label: "Death By Chocolate Cake",
    imgPath: chocolatecake,
  },
];

export default function CustomerPicks() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = useState(false);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt="10px"
      sx={{
        "@media (max-width: 1000px)": {
          marginTop: "35px",
        },
      }}
    >
      <Typography
        fontSize="27px"
        color="#91263C"
        textShadow="2px 2px black"
        sx={{
          "@media (max-width: 1000px)": {
            fontSize: "22px",
          },
        }}
      >
        Our Customers' Favourites
      </Typography>
      <Box
        sx={{
          marginTop: "20px",
          width: "80vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width: 1000px)": {
            display: "none",
            mt: "0px",
          },
        }}
        className="container-fav"
      >
        <NavigateBeforeIcon />
        <SwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            width="60vw"
            height="249px"
            mx="auto"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-around"
              className="container-pick"
            >
              <Box
                sx={{
                  height: "150px",
                  width: "150px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "100px",
                    width: "100px",
                  }}
                  src={redvelvet}
                  alt="Red Velvet Cake"
                  marginX="30px"
                  marginTop="40px"
                  borderRadius="20px 20px 20px 20px"
                  boxShadow="1px 1px #ffa4af"
                />
              </Box>
              <Typography fontSize="17px" color="black">
                Red Velvet Cake
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Buy Now
              </Button>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-around"
              className="container-pick"
            >
              <Box
                sx={{
                  height: "150px",
                  width: "150px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "100px",
                    width: "100px",
                  }}
                  src={blueberrycake}
                  alt="Blueberry Cake"
                  marginX="30px"
                  marginTop="40px"
                  borderRadius="20px 20px 20px 20px"
                  boxShadow="1px 1px #ffa4af"
                />
              </Box>
              <Typography fontSize="17px" color="black" marginBottom="10px">
                Blueberry Cake
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Buy Now
              </Button>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-around"
              className="container-pick"
            >
              <Box
                sx={{
                  height: "150px",
                  width: "150px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "100px",
                    width: "100px",
                  }}
                  src={chocolatecupcake}
                  alt="Chocolate Cup Cake"
                  marginX="30px"
                  marginTop="40px"
                  borderRadius="20px 20px 20px 20px"
                  boxShadow="1px 1px #ffa4af"
                />
              </Box>
              <Typography fontSize="17px" color="black">
                Chocolate Cup Cake
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Buy Now
              </Button>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-around"
              className="container-pick"
            >
              <Box
                sx={{
                  height: "150px",
                  width: "150px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "100px",
                    width: "100px",
                  }}
                  src={chocolatecake}
                  alt="Death By Chocolate"
                  marginX="30px"
                  marginTop="40px"
                  borderRadius="20px 20px 20px 20px"
                  boxShadow="1px 1px #ffa4af"
                />
              </Box>
              <Typography fontSize="17px" color="black">
                Death By Chocolate
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Buy Now
              </Button>
            </Box>
          </Box>
        </SwipeableViews>
        <NavigateNextIcon />
      </Box>
      <Box
        sx={{
          display: "none",
          "@media (max-width: 1000px)": {
            display: "flex",
            marginTop: "80px",
            width: "80vw",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            mt: "0px",
          },
        }}
      >
        {images.map((step, index) => (
          <div key={step.label}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              width="90vw"
              mx="auto"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-around"
                className="container-pick"
              >
                <Box>
                  <Box
                    sx={{
                      height: "200px",
                      width: "50vw",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: "150px",
                        width: "45vw",
                      }}
                      src={step.imgPath}
                      alt={step.label}
                      marginX="30px"
                      marginTop="40px"
                      borderRadius="20px 20px 20px 20px"
                      boxShadow="1px 1px #ffa4af"
                    />
                  </Box>
                  <Typography variant="h6" color="black">
                    {step.label}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Buy Now
                  </Button>
                </Box>
              </Box>
            </Box>
          </div>
        ))}
      </Box>
      <Box className="container-browse">
        <RouterLink component="a" to="/buy" className="atag">
          <Button variant="text">Browse more..</Button>
        </RouterLink>
      </Box>
      <BuyModal open={open} onClose={handleClose} />
    </Box>
  );
}
