import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloseIcon from "@mui/icons-material/Close";
import "../ComponentStyles.css";

export default function BuyModal({ open, onClose }) {
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        "@media (max-width: 1000px)": {
          width: "99vw",
          paddingX: "0px",
          height: "500px",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="410px"
        padding="50px"
        className="container-buymodal"
        sx={{
          "@media (max-width: 1000px)": {
            width: "60vw",
            paddingX: "30px",
            height: "350px",
          },
        }}
      >
        {" "}
        <Typography
          fontSize="20px"
          mb={2}
          mt={2}
          sx={{
            "@media (max-width: 1000px)": {
              fontSize: "18px",
            },
          }}
        >
          Please click the icon below or call us to place your order.
        </Typography>
        <Box display="flex" flexDirection="row">
          <Box>
            {" "}
            <a
              href="https://l.instagram.com/?u=https%3A%2F%2Fwa.me%2Fmessage%2FWJBZOGR764N6A1&e=ATPLrvk2KezxziidOWK726dOuBD_4qqP-Dss0ii9BP3lJz0vj7qfwECVLGLk9kY4jsVqCgr6tKwwo8h_&s=1"
              target="_blank"
              className="buy-atag"
            >
              {" "}
              <WhatsAppIcon
                sx={{
                  marginRight: "0.5vw",
                }}
              />
            </a>
          </Box>
          <Typography
          fontSize="20px"
            marginTop="15px"
            sx={{
              "@media (max-width: 1000px)": {
                fontSize: "18px",
              },
            }}
          >
            {" "}
            +91-6303702232
          </Typography>
        </Box>
        <Typography
          fontSize="20px"
          mt={2}
          mb={2}
          sx={{
            "@media (max-width: 1000px)": {
              fontSize: "18px",
            },
          }}
        >
          Please follow us on Instagram for updates.
        </Typography>
        <Box width="100px">
          <a
            href="https://www.instagram.com/cake_affaire.store/"
            target="_blank"
            className="buy-atag"
          >
            <InstagramIcon
              sx={{
                marginRight: "0.5vw",
              }}
            />
          </a>
        </Box>
        <Box
          alignSelf="end"
          className="closeicon"
          onClick={handleClose}
          sx={{
            "@media (max-width: 1000px)": {
              alignSelf: "center",
              marginTop: "30px",
            },
          }}
        >
          <CloseIcon />
        </Box>
      </Box>
    </Dialog>
  );
}
